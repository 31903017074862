

























































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import { parseISO, differenceInMinutes, format } from "date-fns";

import {
    AnalyticsUserTimeslot,
    LogOutput,
    AttendeeDataObject
} from "@/types/interfaces";

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

export default Vue.extend({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    computed: {
        loading(): boolean {
            return analyticsStore.loading;
        },

        attendeeId(): string {
            return this.$route.params.attendeeId
                ? this.$route.params.attendeeId
                : "";
        },

        attendeeTimelineData(): Array<LogOutput> {
            return analyticsStore.userTimelineData;
        },

        attendeePersonalData(): Partial<AttendeeDataObject> {
            const firstItem = this.attendeeTimelineData.length
                ? this.attendeeTimelineData[0]
                : false;

            const retObj = {
                firstName: "",
                lastName: "",
                companyName: "",
                email: ""
            };

            if (firstItem) {
                retObj.firstName = firstItem.firstName
                    ? firstItem.firstName
                    : "";
                retObj.lastName = firstItem.lastName ? firstItem.lastName : "";
                retObj.email = firstItem.email ? firstItem.email : "";
                retObj.companyName = firstItem.companyName
                    ? firstItem.companyName
                    : "";
            }

            return retObj;
        },

        groupedTimelineData(): Array<AnalyticsUserTimeslot> {
            return this.attendeeTimelineData.reduce(
                (acc, curr, index, array) => {
                    const prevItem = index != 0 ? array[index - 1] : null;
                    const isSameType = prevItem && prevItem.type === curr.type;
                    const hasDuration = this.checkHasDuration(curr.type);
                    const time = parseISO(curr.timestamp);
                    const prevTime = prevItem
                        ? parseISO(prevItem.timestamp)
                        : false;
                    const lessThanTwoMinutes =
                        prevTime && differenceInMinutes(prevTime, time) < 2;

                    const shouldGroup =
                        isSameType && hasDuration && lessThanTwoMinutes;

                    if (shouldGroup) {
                        acc[acc.length - 1].firstTime = curr.timestamp
                            ? curr.timestamp
                            : "";
                    } else {
                        const slotObj = {
                            ...curr,
                            lastTime: curr.timestamp,
                            firstTime: curr.timestamp
                        };

                        delete slotObj.firstName;
                        delete slotObj.lastName;
                        delete slotObj.companyName;
                        delete slotObj.email;

                        acc.push(slotObj);
                    }

                    return acc;
                },
                [] as Array<AnalyticsUserTimeslot>
            );
        }
    },

    mounted() {
        this.getData();
    },

    methods: {
        async getData(): Promise<void> {
            await analyticsStore.getUserTimeline(this.attendeeId);
        },

        checkHasDuration(type: string): boolean {
            const timedLogTypes = ["VideoViewTime", "MeetingUsage"];

            return timedLogTypes.includes(type);
        },

        getFullString(slot: AnalyticsUserTimeslot): string {
            return `${this.getActionString(
                slot.type
            )}<b>${this.getSubjectString(slot)}</b>${this.getTimeString(slot)}`;
        },

        getActionString(type: string): string {
            switch (type) {
                case "SessionEvaluation":
                    return "Submitted an evaluation for session";
                case "SponsorLanding":
                    return "Visited sponsor landing page";
                case "SponsorView":
                    return "Viewed sponsor page";
                case "LoginUser":
                    return "Logged in to the platform";
                case "VideoViewTime":
                    return "Watched livestream session";
                case "VideoView":
                    return "Started watching livestream session";
                case "PosterView":
                    return "Visted poster page";
                case "PosterLanding":
                    return "Visited posters landing page";
                case "MeetingUsage":
                    return "Participated in speakeasy meeting";
                case "PollResult":
                    return "Submitted a poll respose for";
                case "ContinuingEdQuestion":
                    return "Answered a CE question for";
                case "SentMessage":
                    return "Sent a message to another attendee";
                default:
                    return `Performed unknown log action of type ${type}`;
            }
        },

        getSubjectString(slot: AnalyticsUserTimeslot): string {
            let string = "";

            if (slot.sessionName && slot.sessionId) {
                string = ` ${slot.sessionName} (${slot.sessionId})`;
            }

            if (slot.sponsorName && slot.sponsorId) {
                string = ` ${slot.sponsorName} (${slot.sponsorId})`;
            }

            if (slot.posterName && slot.posterId) {
                string = ` ${slot.posterName} (${slot.posterId})`;
            }

            return string;
        },

        getTimeString(slot: AnalyticsUserTimeslot): string {
            if (this.checkHasDuration(slot.type)) {
                const first = parseISO(slot.firstTime);
                const last = parseISO(slot.lastTime);

                const difference = differenceInMinutes(last, first) + 1;

                return ` for ${difference} ${
                    difference == 1 ? "minute" : "minutes"
                }`;
            } else {
                return "";
            }
        },

        formatTimestamp(timestamp: string): string {
            const date = parseISO(timestamp);

            return format(date, "yyyy-MM-dd hh:mm a");
        }
    }
});
